import { BrowserRouter } from "react-router-dom";
import "@mantine/core/styles.css";
import "antd/dist/reset.css";
import { createRoot } from "react-dom/client";
import Router from "./router";
import { MantineProvider } from "@mantine/core";

const App = () => (
  <MantineProvider>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </MantineProvider>
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
