import { Col, Typography, Flex } from "antd";
import Container from "../../common/Container";
import {
  FooterSection,
  Title,
  Large,
  MailOutline,
  FooterContent,
  PhoneOutline,
} from "./styles";

const Footer = () => {
  return (
    <>
      <FooterSection>
        <Container>
          <Flex wrap="wrap" justify="space-evenly" align="center">
            <Col>
              <Title>{"Nosso contato"}</Title>
              <FooterContent>
                <MailOutline />
                <Large to="/">{"contato@raengenharia.com"}</Large>
              </FooterContent>
              <FooterContent>
                <PhoneOutline />
                <Large to="/">{"(11) 99884-8246"}</Large>
              </FooterContent>
            </Col>
            <Col>
              <Typography style={{ marginTop: 40 }}>
                © 2024 R.A Engenharia e Manutenção
              </Typography>
            </Col>
          </Flex>
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
